export const menuItems = [
  {
    id: 2,
    label: "Dashboard",
    icon: "fa fa-tachometer-alt",
    link: "/dashboard",
    can: "dashboard",
  },
  {
    id: 3,
    label: "Admin User",
    icon: "ri-account-circle-line",
    link: "/admin",
    can: "admin-user",
  },
  {
    id: 4,
    label: "Components",
    isTitle: true,
  },
  {
    id: 5,
    label: "Forum",
    link: "/forum",
    can: "forum",
    icon: "mdi mdi-forum",
  },
  // {
  //   id: 39,
  //   label: 'Protected Content',
  //   link: '/protected-content',
  //   can: 'protected-content',
  //   icon: 'mdi mdi-message'
  // },
  {
    id: 6,
    label: "Live Event",
    link: "/live-event",
    can: "live-event",
    icon: "fa fa-tachometer-alt",
  },
  {
    id: 155,
    label: "Protected Live Event",
    link: "/protected-live-event",
    can: "protected-live-event",
    icon: "fa fa-lock",
  },
  {
    id: 159,
    label: "Payment Service",
    icon: "mdi mdi-apps",
    can: checkPermissions([
      "payment",
      "order",
      "product",
      "payment-logs",
      "wallet",
      "wallet-transaction",
      "payment-gateway-type",
      "payment-gateways",
      "pancard",
    ]),
    subItems: [
      {
        id: 162,
        label: "Product",
        link: "/product",
        can: "product",
        icon: "ri ri-product-hunt-fill",
      },
      {
        id: 163,
        label: "Plans",
        link: "/plans",
        can: "plan",
        icon: "ri ri-product-hunt-fill",
      },
      {
        id: 164,
        label: "Gateways",
        link: "/payment-gatways",
        can: "payment-gateways",
        icon: "mdi mdi-credit-card",
      },
      {
        id: 165,
        label: "Gateway Type",
        link: "/payment-type",
        can: "payment-gateway-type",
        icon: "mdi mdi-cards",
      },
      {
        id: 166,
        label: "Order",
        link: "/order",
        can: "order",
        icon: "mdi mdi-comment",
      },
      {
        id: 167,
        label: "Payment",
        link: "/payment",
        can: "payment",
        icon: "ri ri-secure-payment-fill",
      },
      {
        id: 168,
        label: "Payment Logs",
        link: "/payment-logs",
        can: "payment-logs",
        icon: "mdi mdi-history",
      },
      {
        id: 169,
        label: "Wallet",
        link: "/wallet",
        can: "wallet",
        icon: "mdi mdi-wallet",
      },
      {
        id: 176,
        label: "Pancard",
        link: "/pancard",
        can: "pancard",
        icon: "mdi mdi-credit-card-multiple",
      },
      {
        id: 177,
        label: "Wallet Offers",
        link: "/wallet-offers",
        can: "wallet-offers",
        icon: "mdi mdi-tag-multiple",
      },
      {
        id: 177,
        label: "Withdraw Request",
        link: "/wallet-Withdraw-request",
        can: "wallet-withdraw-request",
        icon: "mdi mdi-logout",
      },
      {
        id: 170,
        label: "Wallet Transactions",
        link: "/wallet-transaction",
        can: "wallet-transaction",
        icon: "mdi mdi-wallet",
      },
      {
        id: 171,
        label: "Coupons",
        link: "/coupon",
        can: "coupon",
        icon: "ri ri-coupon-2-fill",
      },
      {
        id: 172,
        label: "Tax",
        link: "/tax",
        can: "tax",
        icon: "ri-percent-fill",
      },
      {
        id: 173,
        label: "Subscription",
        link: "/subscription",
        can: "subscription",
        icon: "mdi mdi-youtube-subscription",
      },
      {
        id: 174,
        label: "Subscription History",
        link: "/subscription-history",
        can: "subscription-history",
        icon: "fa fa-history",
      },
      {
        id: 178,
        label: "Gift Company",
        link: "/gift-company",
        can: "gift-company",
        icon: "mdi mdi-wallet-giftcard",
      },
      {
        id: 179,
        label: "Gift Card",
        link: "/gift-card",
        can: "gift-card",
        icon: "mdi mdi-wallet-giftcard",
      },
      {
        id: 180,
        label: "Gift Card History",
        link: "/gift-card-history",
        can: "gift-card-history",
        icon: "mdi mdi-wallet-giftcard",
      },
    ],
  },
  {
    id: 7,
    label: "Immediate Action",
    link: "/immediate-action",
    can: "immediate-action",
    icon: "fa fa-chart-line",
  },
  {
    id: 8,
    label: "App",
    icon: "mdi mdi-apps",
    can: checkPermissions(["promotion", "webview"]),
    subItems: [
      {
        id: 9,
        label: "Promotion",
        link: "/promotion",
        can: "promotion",
        icon: "mdi mdi-developer-board",
      },
      {
        id: 10,
        label: "Webview",
        link: "/webview",
        can: "webview",
        icon: "mdi mdi-web",
      },
    ],
  },
  {
    id: 11,
    label: "MCI Verification",
    link: "/mci-verification",
    can: "mci-verification",
    icon: "fas fa-file-prescription",
  },
  {
    id: 12,
    label: "Ask Expert",
    icon: "fa fa-user",
    link: "/ask-expert",
    can: "ask-expert",
  },
  {
    id: 13,
    label: "Expert",
    icon: "fa fa-chalkboard-teacher",
    link: "/expert",
    can: "expert",
  },
  {
    id: 14,
    label: "Partner",
    icon: "fa fa-envelope",
    link: "/partner",
    can: "partner",
  },
  {
    id: 15,
    label: "Config",
    icon: "fa fa-cogs",
    link: "/config",
    can: "config",
  },
  {
    id: 16,
    label: "Role",
    icon: "fa fa-chart-line",
    link: "/role",
    can: "role",
  },

  {
    id: 17,
    label: "Slider",
    icon: "fa fa-sliders-h",
    link: "/slider",
    can: "slider",
  },
  {
    id: 18,
    label: "Templates",
    icon: "ri-notification-2-fill",
    can: checkPermissions([
      "email-notification",
      "sms-notification",
      "push-notification",
      "page-notification",
      "master-notification",
    ]),
    subItems: [
      {
        id: 19,
        label: "Email",
        icon: "fa fa-mail-bulk",
        link: "/email-notification",
        can: "email-notification",
      },
      {
        id: 20,
        label: "SMS",
        icon: "fa fa-comment",
        link: "/sms-notification",
        can: "sms-notification",
      },
      {
        id: 21,
        label: "Push",
        icon: "fa fa-bell",
        link: "/push-notification",
        can: "push-notification",
      },
      {
        id: 22,
        label: "Page",
        icon: "fa fa-pager",
        link: "/page-notification",
        can: "page-notification",
      },
      {
        id: 23,
        label: "Master",
        icon: "ri-mastercard-fill",
        link: "/master-notification",
        can: "master-notification",
      },
    ],
  },
  {
    id: 24,
    label: "General",
    icon: "fa fa-chart-line",
    can: checkPermissions([
      "page",
      "country",
      "cityState",
      "speciality",
      "community",
    ]),
    subItems: [
      {
        id: 25,
        label: "Page",
        icon: "fa fa-file",
        link: "/page",
        can: "page",
      },
      {
        id: 26,
        label: "Country",
        icon: "fa fa-globe",
        link: "/country",
        can: "country",
      },
      {
        id: 27,
        label: "CityState",
        icon: "ri ri-global-fill",
        link: "/cityState",
        can: "cityState",
      },
      {
        id: 28,
        label: "Speciality",
        icon: "fa fa-stethoscope",
        link: "/speciality",
        can: "speciality",
      },
      {
        id: 29,
        label: "Community",
        icon: "ri-community-fill",
        link: "/community",
        can: "community",
      },
    ],
  },
  {
    id: 30,
    label: "Content",
    icon: "mdi mdi-content-copy",
    can: checkPermissions([
      "cme",
      "video",
      "case",
      "post",
      "article",
      "series",
      "podcast",
      "question-bank",
      "newsletter",
      "journal",
    ]),
    subItems: [
      {
        id: 31,
        label: "CME",
        link: "/cme",
        can: "cme",
        icon: "mdi mdi-file-multiple",
      },
      {
        id: 182,
        label: "Survey",
        link: "/survey",
        can: "survey",
        icon: "mdi mdi-file-multiple",
      },
      {
        id: 32,
        label: "Video",
        link: "/video",
        can: "video",
        icon: "mdi mdi-video-box",
      },
      {
        id: 33,
        label: "Case",
        icon: "mdi mdi-comment",
        link: "/case",
        can: "case",
      },
      {
        id: 155,
        label: "Post",
        icon: "mdi mdi-post",
        link: "/post",
        can: "post-an-update",
      },
      {
        id: 34,
        label: "Article",
        icon: "fa fa-envelope",
        link: "/article",
        can: "article",
      },
      {
        id: 35,
        label: "Journal",
        icon: "fa fa-newspaper",
        link: "/journal",
        can: "journal-list",
      },
      {
        id: 36,
        label: "Series",
        link: "/series",
        can: "series",
        icon: "mdi mdi-serial-port",
      },
      {
        id: 37,
        label: "Podcast",
        icon: "fa fa-podcast",
        link: "/podcast",
        can: "podcast",
      },
      {
        id: 38,
        label: "Question Bank",
        link: "/question-bank",
        can: "question-bank",
        icon: "mdi mdi-comment-question-outline",
      },
      {
        id: 39,
        label: "Newsletter",
        link: "/newsletter",
        can: "newsletter",
        icon: "mdi mdi-email-newsletter",
      },
      {
        id: 52,
        label: "Detail1",
        link: "/detailOne",
        can: "detail-one",
        icon: "mdi mdi-account-details",
      },
      {
        id: 93,
        label: "Certificate",
        link: "/certificate",
        can: "certificate",
        icon: "fa fa-certificate",
      },
    ],
  },
  // {
  //   id: 39,
  //   label: "Communication",
  //   can: checkPermissions(["communication"]),
  //   icon: "mdi mdi-phone-in-talk",
  //   subItems: [
  //     {
  //       id: 40,
  //       label: "Notification",
  //       link: "/communication",
  //       can: "communication",
  //       icon: "ri-app-store-fill",
  //     },
  //   ],
  // },
  {
    id: 45,
    label: "Campaigns",
    can: checkPermissions(["campaign"]),
    icon: "ri-notification-3-fill",
    subItems: [
      {
        id: 46,
        label: "Campaign",
        link: "/campaign",
        can: "campaign",
        icon: "ri-notification-3-fill",
      },
      {
        id: 48,
        label: "Touchpoint",
        link: "/touchpoint-list",
        can: "campaign",
        icon: "ri-notification-badge-fill",
      },
      {
        id: 47,
        label: "Analytics",
        link: "/main-analytics",
        can: "main-analytics",
        icon: "fa fa-chart-bar",
      },
    ],
  },
  {
    id: 41,
    label: "Data Filters",
    link: "/dataFilters",
    can: "dataFilters",
    icon: "mdi mdi-filter",
  },
  {
    id: 42,
    label: "Universal Member Upload",
    link: "/universal-member-upload",
    can: "universal-member-upload",
    icon: "fas fa-file-upload",
  },
  {
    id: 43,
    label: "Brand Tool",
    can: checkPermissions(["forum-tool", "stages"]),
    icon: "mdi mdi-toolbox",
    subItems: [
      {
        id: 46,
        label: "Tool Creation",
        link: "/forum-tool",
        can: "forum-tool",
        icon: "mdi mdi-toolbox-outline",
      },
      {
        id: 47,
        label: "Reviewer Workflow",
        link: "/stage",
        can: "stages",
        icon: "ri ri-tools-line",
      },
    ],
  },
  {
    id: 44,
    label: "Meta Tag",
    icon: "mdi mdi-comment",
    link: "/metatag",
    can: "MetaTag",
  },
  {
    id: 49,
    label: "DigiMr Management",
    can: checkPermissions(["DigiMr"]),
    icon: "ri-notification-badge-fill",
    subItems: [
      {
        id: 46,
        label: "Telecallers",
        link: "/digiMr-management",
        can: "DigiMr",
        icon: "mdi mdi-toolbox-outline",
      },
      {
        id: 47,
        label: "Field Rep Tracking",
        link: "/field-rep-tracking",
        can: "DigiMr",
        icon: "fa fa-map-marker",
      },
    ],
  },
  {
    id: 50,
    label: "Member Tag Type",
    link: "/member-tag-type",
    can: "member-tag-type",
    icon: "fa fa-user",
  },
  {
    id: 51,
    label: "Member List",
    link: "/member-list",
    can: "member-list",
    icon: "fa fa-user",
  },
  {
    id: 200,
    label: "Doctor Onboarding",
    link: "/doctor-onboarding",
    can: "onboard-doctor",
    icon: "fa fa-user-md",
  },
  {
    id: 163,
    label: "GP Data",
    link: "/gpdata",
    can: "gp_data",
    icon: "fa fa-user",
  },
  {
    id: 92,
    label: "Universal Member",
    link: "/universal-member",
    can: "universal-member",
    icon: "fa fa-user",
  },
  {
    id: 52,
    label: "Certificate",
    link: "/certificate",
    can: "certificate",
    icon: "fa fa-certificate",
  },
  {
    id: 53,
    label: "Unsubscribe",
    link: "/unsubscribe",
    can: "unsubscribe",
    icon: "mdi mdi-arrow-right-bold-box-outline",
  },
  {
    id: 54,
    label: "Points",
    link: "/points",
    // can: "points",
    icon: "mdi mdi-arrow-right-bold-box-outline",
  },
  {
    id: 154,
    label: "Admin Audit List",
    link: "/admin-audit-logs",
    can: "admin-audit-logs",
    icon: "fa fa-history",
  },
  {
    id: 156,
    label: "Email Content",
    link: "/email-content",
    can: "email",
    icon: "fa fa-mail-bulk",
  },
  {
    id: 186,
    label: "Field Rep App",
    icon: "mdi mdi-apps",
    can: checkPermissions(["field-rep-app-campaigns", "field-rep-app-cards", "field-rep-app-campaign-content", "field-rep-app-reps"]),
    subItems: [
      {
        id: 187,
        label: "Campaigns",
        link: "/field-rep-app/campaigns",
        can: "field-rep-app-campaigns",
        icon: "mdi mdi-developer-board",
      },
      {
        id: 188,
        label: "Card",
        link: "/field-rep-app/card",
        can: "field-rep-app-cards",
        icon: "mdi mdi-web",
      },
      {
        id: 189,
        label: "Campaign Content",
        link: "/field-rep-app/campaign-contents",
        can: "field-rep-app-campaign-content",
        icon: "mdi mdi-developer-board",
      },
      {
        id: 190,
        label: "Speciality Data",
        link: "/field-rep-app/campaign-speciality-data",
        can: "field-rep-app-campaign-content",
        icon: "mdi mdi-developer-board",
      },
      {
        id: 191,
        label: "Brand Speciality Data",
        link: "/field-rep-app/campaign-brand-speciality-data",
        can: "field-rep-app-campaign-content",
        icon: "mdi mdi-developer-board",
      },
      {
        id: 199,
        label: "Field Mr Reps",
        link: "/field-rep-app/rep",
        can: "field-rep-app-reps",
        icon: "mdi mdi-account-tie",
      },
    ],
  },
  {
    id: 196,
    label: "Medisage Blog",
    icon: "mdi mdi-notebook-outline",
    can: checkPermissions(["medi-blog-posts","medi-blog-categories"]),
    subItems: [
      {
        id: 197,
        label: "Posts",
        link: "/medi-blog/posts",
        can: "medi-blog-posts",
        icon: "mdi mdi-post-outline",
      },
      {
        id: 198,
        label: "Category",
        link: "/medi-blog/categories",
        can: "medi-blog-categories",
        icon: "mdi mdi-shape-outline",
      },
    ]
  },
  {
    id: 157,
    label: "Feedback",
    link: "/feedback",
    can: "feedback",
    icon: "ri ri-feedback-fill",
  },
  {
    id: 158,
    label: "Patient Query",
    link: "/patient-query",
    can: "patient-query",
    icon: "mdi mdi-comment-question-outline",
  },
  {
    id: 184,
    label: "Comment",
    link: "/comment",
    can: "comments",
    icon: "mdi mdi-comment",
  },
  {
    id: 185,
    label: "Institutes",
    link: "/institute-master",
    can: "institute-master",
    icon: "mdi mdi-school",
  },
  {
    id: 160,
    label: "Profile Rating",
    link: "/rating",
    can: "doctor-rating",
    icon: "fa fa-star",
  },
  {
    id: 181,
    label: "URL Shortner",
    link: "/url-shortner",
    can: "url-shortner",
    icon: "mdi mdi-link",
  },
  {
    id: 182,
    label: "Qr Code",
    link: "/qr-code",
    can: "qr-code",
    icon: "mdi mdi-qrcode",
  },
  {
    id: 183,
    label: "Clinic",
    link: "/clinic",
    can: "clinic",
    icon: "fa fa-hospital",
  },
  {
    id: 190,
    label: "Survey Payouts",
    link: "/survey-payouts",
    can: "survey-payout",
    icon: "mdi mdi-file-multiple",
  },
  {
    id: 191,
    label: "Patient Library",
    icon: "mdi mdi-apps",
    can: checkPermissions(["patient-library-template","patient-library-category","patient-library-sub-category","patient-library-language"]),
    subItems: [
      {
        id: 192,
        label: "Templates",
        link: "/patient-library/templates",
        can: "patient-library-template",
        icon: "mdi mdi-file-outline",
      },
      {
        id: 193,
        label: "Category",
        link: "/patient-library/categories",
        can: "patient-library-category",
        icon: "mdi mdi-folder-outline",
      },
      {
        id: 194,
        label: "Sub Category",
        link: "/patient-library/sub-categories",
        can: "patient-library-sub-category",
        icon: "mdi mdi-folder-multiple-outline",
      },
      {
        id: 195,
        label: "Languages",
        link: "/patient-library/languages",
        can: "patient-library-language",
        icon: "mdi mdi-earth",
      },
    ],
  },
  {
    id: 186,
    label: "OMS Gift Card",
    link: "/osm-gift-card",
    can: "gift-card",
    icon: "fa fa-gift",
  },
];

export function checkPermissions(array) {
  var CryptoJS = require("crypto-js");
  let element = "";
  const permissions = JSON.parse(
    CryptoJS.AES.decrypt(
      localStorage.getItem("token"),
      process.env.VUE_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8)
  ).permissions;
  for (let index = 0; index < array.length; index++) {
    if (permissions.includes(array[index])) {
      element = array[index];
    }
  }
  return element;
}
